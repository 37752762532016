<template>
  <ul class="all-tickets">
    <li
      class="ticket"
      v-for="ticket in tickets"
      :key="ticket.id"
      @click="$emit('view-ticket', ticket.id)"
    >
      <div class="ticket-status">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 49.39 49.39"
          class="ticket-status_icon"
          :class="
            ticket.status == 'Open'
              ? 'open'
              : ticket.status == 'Closed'
              ? 'closed'
              : 'onhold'
          "
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M40.74,15.29H8.65a2.49,2.49,0,0,0-2.49,2.49V31.61A2.49,2.49,0,0,0,8.65,34.1H40.74a2.48,2.48,0,0,0,2.49-2.49V17.78A2.48,2.48,0,0,0,40.74,15.29Zm1.82,16.32a1.83,1.83,0,0,1-1.82,1.82H15.3v-.34h-.67v.34h-6a1.82,1.82,0,0,1-1.81-1.82V17.78A1.82,1.82,0,0,1,8.65,16h6v.34h.67V16H40.74a1.83,1.83,0,0,1,1.82,1.82Z"
              />
              <rect
                class="cls-1"
                x="14.63"
                y="25.34"
                width="0.68"
                height="1.29"
              />
              <rect
                class="cls-1"
                x="14.63"
                y="20.18"
                width="0.68"
                height="1.29"
              />
              <rect
                class="cls-1"
                x="14.63"
                y="30.51"
                width="0.68"
                height="1.29"
              />
              <rect
                class="cls-1"
                x="14.63"
                y="17.59"
                width="0.68"
                height="1.29"
              />
              <rect
                class="cls-1"
                x="14.63"
                y="27.92"
                width="0.68"
                height="1.29"
              />
              <rect
                class="cls-1"
                x="14.63"
                y="22.76"
                width="0.68"
                height="1.29"
              />
              <rect
                class="cls-1"
                x="18.91"
                y="18.42"
                width="10.07"
                height="0.68"
              />
              <rect
                class="cls-1"
                x="19.21"
                y="20.96"
                width="17.1"
                height="0.68"
              />
              <rect
                class="cls-1"
                x="19.68"
                y="26.79"
                width="12.04"
                height="0.68"
              />
              <rect
                class="cls-1"
                x="19.68"
                y="28.56"
                width="10.08"
                height="0.68"
              />
              <rect
                class="cls-1"
                x="35.17"
                y="30.17"
                width="4.18"
                height="0.68"
              />
              <path
                class="cls-1"
                d="M24.7,49.39A24.7,24.7,0,1,1,49.39,24.7,24.73,24.73,0,0,1,24.7,49.39ZM24.7.85A23.85,23.85,0,1,0,48.54,24.7,23.88,23.88,0,0,0,24.7.85Z"
              />
            </g>
          </g>
        </svg>
        <span class="ticket-status_label">{{ ticket.status }}</span>
      </div>
      <div class="ticket-info">
        <span class="title">{{ ticket.subject }}</span>
        <div class="info">
          <div class="ticket-info_number">
            <img
              :src="require('@/assets/images/icon-ticket_number.svg')"
              alt="Number"
            />
            <span>#{{ ticket.ticketNumber }}</span>
          </div>
          <div class="ticket-info_type">
            <img
              :src="require('@/assets/images/icon-ticket_type.svg')"
              alt="Number"
            />
            <span :title="ticket.departmentId">{{ ticket.departmentId }}</span>
          </div>
          <div class="ticket-info_time">
            <img
              :src="require('@/assets/images/icon-ticket_time.svg')"
              alt="Number"
            />
            <span>{{ ticketDate(ticket.createdTime) }}</span>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
const moment = require('moment')
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'AllTickets',
  props: ['tickets'],
  methods: {
    ticketDate(date) {
      return moment(date).calendar()
    }
  }
}
</script>

<style lang="scss" scoped>
.all-tickets {
  list-style: none;
  width: 100%;
  padding: 1rem 0 !important;
  li.ticket {
    display: flex;
    align-items: flex-end;
    width: 100%;
    margin: 15px 0;
    padding: 10px 5px;
    border-radius: 5px;
    &:hover {
      background-color: lighten($blue, 55%);
      cursor: pointer;
    }
    .ticket-status {
      text-align: center;
      width: 85px;
      .ticket-status_icon {
        width: 45px;
        height: 45px;
        &.open {
          fill: $green;
        }
        &.onhold {
          fill: $blue;
        }
        &.closed {
          fill: $red;
        }
      }
    }
    .ticket-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      width: 100%;
      .title {
        font-size: 22px;
        color: $blue;
      }
      .info {
        display: flex;
        width: 100%;
        div {
          display: block;
          width: 150px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &.ticket-info_time {
            min-width: 230px;
          }
          img {
            max-height: 16px;
            max-width: 31px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
