<template>
  <div>
    <div v-if="!ticketView">
      <h3 class="title-with_border">{{ $t('support.tickets.filter-by') }}</h3>
      <div class="va-row">
        <div class="flex md12">
          <div class="va-row align--center justify--space-between">
            <div class="d-flex">
              <div>
                <button
                  class="btn btn-primary rounded btn-micro dropdown-toggle"
                  type="button"
                  slot="actuator"
                >
                  {{
                    selectedDepartment.name ||
                      $t('support.tickets.filters.department')
                  }}
                  <i class="ion-ios-arrow-down arrow-down" />
                </button>
                <vuestic-dropdown placement="bottom">
                  <template>
                    <a
                      class="dropdown-item"
                      v-for="(department, index) in [
                        dummyDepartment,
                        ...departments
                      ]"
                      :key="index"
                      @click="selectDepartment(department)"
                      >{{ department.name }}</a
                    >
                  </template>
                </vuestic-dropdown>
              </div>
              <div class="mx-2">
                <button
                  class="btn btn-primary rounded btn-micro dropdown-toggle"
                  type="button"
                  slot="actuator"
                >
                  {{
                    selectedStatus.name || $t('support.tickets.filters.status')
                  }}
                  <i class="ion-ios-arrow-down arrow-down" />
                </button>
                <vuestic-dropdown placement="bottom">
                  <template>
                    <a
                      class="dropdown-item"
                      v-for="(status, index) in statusList"
                      :key="index"
                      @click="selectStatus(status)"
                      >{{ status.name }}</a
                    >
                  </template>
                </vuestic-dropdown>
              </div>
            </div>
            <div class="form-group with-icon-left">
              <div class="input-group">
                <input
                  class="search-input"
                  name="input-icon-left"
                  v-model="filter"
                  required
                  :placeholder="$t('support.tickets.filters.search-by-subject')"
                />
                <i
                  class="glyphicon glyphicon-search icon-left input-icon search-icon"
                ></i>
                <half-circle-spinner
                  slot="loading"
                  :animation-duration="1500"
                  :size="20"
                  v-show="loading"
                  color="#74b9ff"
                  class="filter-loading"
                />
              </div>
            </div>
            <div class="d-flex">
              <div class="mx-2">
                <button
                  class="btn btn-outline-primary rounded btn-micro dropdown-toggle"
                  type="button"
                  slot="actuator"
                >
                  {{ perPage }} {{ $t('support.tickets.filters.per-page') }}
                  <i class="ion-ios-arrow-down arrow-down" />
                </button>
                <vuestic-dropdown placement="bottom">
                  <template>
                    <a
                      class="dropdown-item"
                      v-for="(option, index) in options"
                      :key="index"
                      @click="selectedItemsPerPage(option)"
                      >{{ option }} {{ $t('tables.dataTable.perPage') }}</a
                    >
                  </template>
                </vuestic-dropdown>
              </div>
              <button
                class="btn btn-primary rounded btn-micro"
                type="button"
                @click="askInno"
              >
                {{ $t('support.tickets.filters.ask-inno') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AllTickets
      :tickets="tickets"
      v-if="!ticketsLoading && !loadingTicket && !ticketView"
      v-on:view-ticket="viewTicket"
    />
    <ViewTicket
      :ticket="ticket"
      v-if="!ticketsLoading && !loadingTicket && ticketView"
      v-on:go-back="ticketView = false"
    />
    <half-circle-spinner
      slot="loading"
      :animation-duration="1500"
      :size="50"
      color="#74b9ff"
      v-show="ticketsLoading || loadingTicket"
      class="my-5 mx-auto"
    />
    <div class="text-center" v-if="!loadingTicket && !ticketView && tickets">
      <paginate
        :page-count="totalPages"
        :click-handler="clickCallback"
        prev-text="<"
        next-text=">"
        container-class="btn-group"
        page-link-class="btn-nav btn btn-primary pagination-link-btn btn-sm"
        active-class="btn-nav btn btn-primary pagination-link-btn btn-sm focus"
        disabled-class="btn-nav btn btn-primary pagination-link-btn btn-sm disabled"
        next-link-class="btn-nav btn btn-primary pagination-link-btn btn-sm"
        prev-link-class="btn-nav btn btn-primary pagination-link-btn btn-sm"
        :no-li-surround="true"
      >
      </paginate>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import AllTickets from './mytickets/AllTickets'
import ViewTicket from './mytickets/ViewTicket'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'
import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)
export default {
  name: 'MyTicketsTab',
  components: {
    AllTickets,
    ViewTicket,
    HalfCircleSpinner
  },
  data() {
    return {
      ticketsLoading: false,
      loadingTicket: false,
      ticketView: false,
      tickets: [],
      totalPages: 1,
      ticket: {},
      filter: '',
      loading: false,
      perPage: 10,
      options: [10, 25, 50],
      selectedDepartment: {
        id: 0,
        name: ''
      },
      dummyDepartment: {
        id: 0,
        name: ''
      },
      selectedStatus: {
        id: 0,
        name: ''
      },
      statusList: [
        {
          id: 0,
          name: ''
        },
        {
          id: 1,
          name: 'Open'
        },
        {
          id: 2,
          name: 'Closed'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['ticketSubmitted', 'departments'])
  },
  watch: {
    selectedStatus(val) {
      this.getTickets()
    },
    ticketSubmitted(val) {
      if (val) {
        this.getTickets()
        this.$store.commit('newTicketSubmitted', false)
      }
    },
    filter(val) {
      this.getTickets()
    },
    perPage(val) {
      this.getTickets()
    },
    selectedDepartment(val) {
      this.getTickets()
    }
  },
  methods: {
    ...mapActions(['fetchTickets', 'fetchTicketContent']),
    async clickCallback(pageNum) {
      this.getTickets(pageNum)
    },
    async getTickets(page = 1) {
      this.ticketsLoading = true
      const response = await this.fetchTickets({
        filter: this.filter,
        status: this.selectedStatus.name,
        department: this.selectedDepartment.id,
        perPage: this.perPage,
        page
      })
      this.tickets = response.data.data
      this.totalPages = response.data.last_page
      this.ticketsLoading = false
    },
    async viewTicket(id) {
      this.loadingTicket = true
      const response = await this.fetchTicketContent(id)
      if (response.success) {
        this.ticket = response.data
        this.ticketView = true
      }
      this.loadingTicket = false
    },
    selectedItemsPerPage(optionValue) {
      this.perPage = optionValue
    },
    selectDepartment(department) {
      this.selectedDepartment = department
    },
    selectStatus(status) {
      this.selectedStatus = status
    },
    askInno() {
      document.getElementById('tab-0').click()
    }
  },
  created() {
    // if (!this.tickets.length) {
    this.getTickets()
    // }
  }
}
</script>

<style lang="scss" scoped>
.filter-loading {
  position: absolute;
  top: 8px;
  right: 1rem;
}
h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  &.title-with_border {
    &:after {
      content: '';
      display: block;
      width: 100%;
      border-bottom: 2px solid $lighter-gray;
      margin-top: 5px;
    }
  }
}
.form-group {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.search-input {
  border: 1px solid $light-gray;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  padding: 10px 30px !important;
  height: 36px;
}
.search-icon {
  padding: 4px 8px;
}
.btn.dropdown-toggle,
.dropdown-menu {
  min-width: 12rem;
  max-width: 12rem;
}
.dropdown-toggle {
  box-shadow: none;
  border: 1px solid $main-light;
}
.dropdown-toggle:hover,
.dropdown-toggle:active,
.dropdown-toggle:focus {
  background: transparent;
  background-color: transparent !important;
  border-color: lighten($blue, 10%) !important;
  box-shadow: none !important;
  color: lighten($blue, 10%);
}
.dropdown-item,
.dropdown-toggle {
  text-transform: uppercase;
}

label {
  margin-bottom: 0;
}
.va-row:not(:only-child) {
  margin: 20px -0.5rem !important;
}
@media (max-width: 768px) {
  .form-group {
    width: 80%;
  }
}
</style>
