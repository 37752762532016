var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h5',{staticClass:"ticket-no"},[_vm._v("Ticket No. #"+_vm._s(_vm.ticket.ticketNumber))]),_c('h5',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.ticket.subject))]),_c('span',{staticClass:"ticket-date text-muted"},[_c('img',{attrs:{"src":require('@/assets/images/icon-ticket_time.svg'),"alt":"Number"}}),_vm._v(" "+_vm._s(_vm.ticketDate(_vm.ticket.createdTime))+" ")]),_c('div',{staticClass:"d-flex align--center justify--center"},[_c('button',{staticClass:"btn btn-outline-primary rounded btn-micro",attrs:{"type":"button"}},[_vm._v(" Department: "+_vm._s(_vm.ticket.department)+" ")]),_c('button',{staticClass:"btn rounded btn-micro mx-2",class:_vm.ticket.status == 'Open'
          ? 'btn-outline-success'
          : _vm.ticket.status == 'Closed'
          ? 'btn-outline-danger'
          : 'btn-outline-warning',attrs:{"type":"button"}},[_vm._v(" Status: "+_vm._s(_vm.ticket.status)+" ")]),_c('button',{staticClass:"btn btn-outline-primary rounded btn-micro mx-2",attrs:{"type":"button"}},[_vm._v(" Priority: "+_vm._s(_vm.ticket.priority)+" ")]),_c('button',{staticClass:"btn btn-outline-primary rounded btn-micro mx-2",attrs:{"type":"button"}},[_vm._v(" Channel: "+_vm._s(_vm.ticket.channel)+" ")]),_c('a',{staticClass:"btn btn-primary rounded btn-micro ml-auto",attrs:{"target":"_blank","href":`https://${_vm.ticket.replyUrl}`}},[_vm._v(" Reply ")])]),_c('br'),_c('hr'),_c('div',{staticClass:"ticket-details"},[_c('div',{staticClass:"ticket-details_header"},[_vm._m(0),_c('div',[(_vm.currentUser)?_c('h4',{staticClass:"username"},[_vm._v(_vm._s(_vm.currentUser.name))]):_vm._e(),_c('span',{staticClass:"ticket-date text-muted"},[_vm._v(" "+_vm._s(_vm.ticketDate(_vm.ticket.createdTime))+" ")])])]),_c('div',{staticClass:"ticket-content",domProps:{"innerHTML":_vm._s(_vm.ticket.description)}})]),(_vm.ticket.replies.length > 0)?_c('div',{staticClass:"ticket-replies"},[_c('h5',[_vm._v("Ticket replies")]),_c('hr'),_vm._l((_vm.ticket.replies),function(reply,index){return _c('div',{key:index,staticClass:"ticket-details"},[_c('div',{staticClass:"ticket-details_header"},[_vm._m(1,true),_c('div',[_c('h4',{staticClass:"username"},[_vm._v(_vm._s(reply.authorName))]),_c('span',{staticClass:"ticket-date text-muted"},[_vm._v(" "+_vm._s(_vm.ticketDate(reply.createdTime))+" ")])])]),_c('div',{staticClass:"ticket-content",domProps:{"innerHTML":_vm._s(reply.summary)}})])})],2):_vm._e(),_c('button',{staticClass:"btn btn-primary btn-sm rounded",on:{"click":function($event){return _vm.$emit('go-back')}}},[_vm._m(2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ticket-img"},[_c('img',{attrs:{"src":require("@/assets/images/avatar.png"),"alt":"Profile"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ticket-img"},[_c('img',{attrs:{"src":require("@/assets/images/avatar.png"),"alt":"Profile"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-with-icon-content"},[_c('i',{staticClass:"fa fa-arrow-left"}),_vm._v(" Back ")])
}]

export { render, staticRenderFns }