<template>
  <div>
    <h5 class="ticket-no">Ticket No. #{{ ticket.ticketNumber }}</h5>
    <h5 class="text-primary">{{ ticket.subject }}</h5>
    <span class="ticket-date text-muted">
      <img
        :src="require('@/assets/images/icon-ticket_time.svg')"
        alt="Number"
      />
      {{ ticketDate(ticket.createdTime) }}
    </span>
    <div class="d-flex align--center justify--center">
      <button class="btn btn-outline-primary rounded btn-micro" type="button">
        Department: {{ ticket.department }}
      </button>
      <button
        class="btn rounded btn-micro mx-2"
        :class="
          ticket.status == 'Open'
            ? 'btn-outline-success'
            : ticket.status == 'Closed'
            ? 'btn-outline-danger'
            : 'btn-outline-warning'
        "
        type="button"
      >
        Status: {{ ticket.status }}
      </button>
      <button
        class="btn btn-outline-primary rounded btn-micro mx-2"
        type="button"
      >
        Priority: {{ ticket.priority }}
      </button>
      <button
        class="btn btn-outline-primary rounded btn-micro mx-2"
        type="button"
      >
        Channel: {{ ticket.channel }}
      </button>
      <a
        class="btn btn-primary rounded btn-micro ml-auto"
        target="_blank"
        :href="`https://${ticket.replyUrl}`"
      >
        Reply
      </a>
    </div>
    <br />
    <hr />
    <div class="ticket-details">
      <div class="ticket-details_header">
        <div class="ticket-img">
          <img src="@/assets/images/avatar.png" alt="Profile" />
        </div>
        <div>
          <h4 class="username" v-if="currentUser">{{ currentUser.name }}</h4>
          <span class="ticket-date text-muted">
            {{ ticketDate(ticket.createdTime) }}
          </span>
        </div>
      </div>
      <div class="ticket-content" v-html="ticket.description"></div>
    </div>
    <div class="ticket-replies" v-if="ticket.replies.length > 0">
      <h5>Ticket replies</h5>
      <hr />
      <div
        class="ticket-details"
        v-for="(reply, index) in ticket.replies"
        :key="index"
      >
        <div class="ticket-details_header">
          <div class="ticket-img">
            <img src="@/assets/images/avatar.png" alt="Profile" />
          </div>
          <div>
            <h4 class="username">{{ reply.authorName }}</h4>
            <span class="ticket-date text-muted">
              {{ ticketDate(reply.createdTime) }}
            </span>
          </div>
        </div>
        <div class="ticket-content" v-html="reply.summary"></div>
      </div>
    </div>
    <button class="btn btn-primary btn-sm rounded" @click="$emit('go-back')">
      <div class="btn-with-icon-content">
        <i class="fa fa-arrow-left"></i>
        Back
      </div>
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const moment = require('moment')

export default {
  name: 'ViewTicket',
  props: ['ticket'],
  data() {
    return {
      loadingTicket: false
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  methods: {
    ticketDate(date) {
      return moment(date).calendar()
    }
  }
}
</script>

<style lang="scss" scoped>
h5 {
  margin-top: 0;
  font-size: 1.2rem;
}
.ticket-date {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  img {
    max-height: 16px;
    max-width: 31px;
    margin-right: 10px;
  }
}
.ticket-details {
  .ticket-details_header {
    display: flex;
    align-items: center;
    .ticket-img {
      width: 60px;
      height: 60px;
      // border: 2px solid $blue;
      border-radius: 50%;
      margin-right: 20px;
      img {
        width: 100%;
      }
    }
    .username {
      margin: 0;
      font-size: 1.5rem;
      text-transform: capitalize;
    }
    .ticket-date {
      margin-bottom: 0;
    }
  }
  .ticket-content {
    padding: 30px 80px;
  }
}
.ticket-replies {
  margin-top: 1rem;
  .ticket-details {
    padding-left: 60px;
  }
}
</style>
