<template>
  <div>
    <h3>{{ $t('support.ask-inno.title') }}</h3>
    <div class="form">
      <span class="text-primary">{{ $t('support.ask-inno.subtitle') }}</span>
      <field
        class="mb-3"
        :label="$t('support.ask-inno.form.department.label')"
        :error="errors.departmentId"
      >
        <VSelect
          :options="departments"
          :placeholder="$t('support.ask-inno.form.department.placeholder')"
          v-model="model.department"
        ></VSelect>
      </field>
      <field
        class="mb-3"
        :label="$t('support.ask-inno.form.phone.label')"
        :placeholder="$t('support.ask-inno.form.phone.placeholder')"
        :error="errors.phone"
        v-model="model.phone"
      >
      </field>
      <field
        class="mb-3"
        :label="$t('support.ask-inno.form.subject.label')"
        :placeholder="$t('support.ask-inno.form.subject.placeholder')"
        :error="errors.subject"
        v-model="model.subject"
      >
      </field>
      <field
        class="mb-3"
        :label="$t('support.ask-inno.form.description.label')"
        :error="errors.description"
      >
        <wysiwyg
          :placeholder="$t('support.ask-inno.form.description.placeholder')"
          v-model="model.description"
        />
      </field>
      <field
        class="mb-3"
        :label="$t('support.ask-inno.form.product-name.label')"
        :error="errors.productId"
      >
        <VSelect
          :options="products"
          :placeholder="$t('support.ask-inno.form.product-name.placeholder')"
          v-model="model.product"
        ></VSelect>
      </field>
      <br />
      <hr />
      <field
        class="mb-3"
        :label="$t('support.ask-inno.form.priority.label')"
        :error="errors.priority"
      >
        <VSelect
          :options="priorities"
          :placeholder="$t('support.ask-inno.form.priority.placeholder')"
          v-model="model.priority"
        ></VSelect>
      </field>
      <field
        class="mb-3"
        :label="$t('support.ask-inno.form.classification.label')"
        :error="errors.classification"
      >
        <VSelect
          :options="classifications"
          :placeholder="$t('support.ask-inno.form.classification.placeholder')"
          v-model="model.classification"
        ></VSelect>
      </field>

      <field
        class="mb-3"
        :label="$t('support.ask-inno.form.attachment.label')"
        :error="errors.attachment"
      >
        <dropzone
          :options="{
            paramName: 'file',
            acceptedFiles: '.csv',
            maxFiles: 1
          }"
          v-model="model.attachment"
        >
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title">
              {{ $t('support.ask-inno.form.attachment.title') }}
            </h3>
            <div class="subtitle">
              {{ $t('support.ask-inno.form.attachment.subtitle') }}
            </div>
          </div>
        </dropzone>
      </field>
      <div class="d-flex justify-center">
        <div class="flex md2 xs6 mx-1">
          <button
            class="btn btn-primary btn-sm btn-block"
            @click="onCreateTicket"
            :disabled="loading"
          >
            <span v-if="loading">
              <half-circle-spinner
                slot="loading"
                :animation-duration="1500"
                :size="25"
                color="#74b9ff"
                class="input-loader m-auto"
              />
            </span>
            <span v-else>
              {{ $t('support.ask-inno.form.submit') }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Field from '../../../elements/Field.vue'
import VSelect from '../../../elements/Select.vue'
import Dropzone from '../../../elements/Dropzone.vue'
import { object, string } from 'yup'
import { yupToKV } from '../../../utils/yup'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'

export default {
  name: 'AskInnoTab',
  components: {
    Field,
    VSelect,
    Dropzone,
    HalfCircleSpinner
  },
  data() {
    return {
      loading: false,
      priorities: [
        {
          id: 'High',
          name: 'High'
        },
        {
          id: 'Medium',
          name: 'Medium'
        },
        {
          id: 'Low',
          name: 'Low'
        }
      ],
      classifications: [
        {
          id: 'Problem',
          name: 'Problem'
        },
        {
          id: 'Question',
          name: 'Question'
        },
        {
          id: 'Request',
          name: 'Request'
        },
        {
          id: 'Others',
          name: 'Others'
        }
      ],
      model: {
        department: '',
        phone: '',
        subject: '',
        description: '',
        product: '',
        priority: {
          id: 'High',
          name: 'High'
        },
        classification: {
          id: 'Problem',
          name: 'Problem'
        },
        attachment: []
      },
      errors: {}
    }
  },
  computed: {
    ...mapGetters(['departments', 'products']),
    createTicketSchema() {
      return object().shape({
        departmentId: string()
          .trim()
          .required(this.$t('support.ask-inno.errors.department.required')),
        phone: string()
          .trim()
          .matches(/^\d+$/, this.$t('support.ask-inno.errors.phone.invalid'))
          .required(this.$t('support.ask-inno.errors.phone.required')),
        subject: string()
          .trim()
          .required(this.$t('support.ask-inno.errors.subject.required')),
        description: string()
          .trim()
          .required(this.$t('support.ask-inno.errors.description.required')),
        productId: string()
          .trim()
          .required(this.$t('support.ask-inno.errors.product-name.required')),
        priority: string()
          .trim()
          .required(this.$t('support.ask-inno.errors.priority.required')),
        classification: string()
          .trim()
          .required(this.$t('support.ask-inno.errors.classification.required'))
      })
    }
  },
  methods: {
    ...mapActions(['getDepartments', 'getProducts', 'openTicket']),
    async onCreateTicket() {
      this.$emit('ticketCreated')

      const data = {
        ...this.model,
        departmentId: this.model.department.id,
        productId: this.model.product.id,
        priority: this.model.priority.id,
        classification: this.model.classification.id
      }
      await this.createTicketSchema
        .validate(data, { abortEarly: false })
        .catch(err => {
          // console.log({err});
          this.errors = yupToKV(err)
        })

      if (!this.createTicketSchema.isValidSync(data)) {
        return
      }

      this.errors = {}
      this.loading = true
      try {
        await this.openTicket(data)
        this.toast('ticket created successfully')
        this.reset()
        this.$emit('ticketCreated')
      } catch (error) {
        this.toast(error.response.data.message, { type: 'error' })
      } finally {
        this.loading = false
      }
    },
    reset() {
      this.model = {
        department: '',
        phone: '',
        subject: '',
        description: '',
        product: '',
        priority: {
          id: 'High',
          name: 'High'
        },
        classification: {
          id: 'Problem',
          name: 'Problem'
        },
        attachment: []
      }
    }
  },
  created() {
    this.getDepartments()
    this.getProducts()
  }
}
</script>

<style lang="scss" scoped>
h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  &:after {
    content: '';
    display: block;
    width: 100%;
    border-bottom: 2px solid $lighter-gray;
    margin-top: 5px;
  }
}
.form-group {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
label {
  margin-bottom: 0;
}
.va-row:not(:only-child) {
  margin: 20px -0.5rem !important;
}
</style>
<style>
.vuestic-simple-select .form-group {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
@media (max-width: 767px) {
  .editr--toolbar {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}
</style>
