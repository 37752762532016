<template>
  <div>
    {{ tab }}
    <vuestic-tabs
      :names="[$t('support.tabs.ask-inno'), $t('support.tabs.my-tickets')]"
      ref="tabs"
      class="tabs"
    >
      <div
        :slot="$t('support.tabs.ask-inno')"
        @ticketCreated="tabs.currentActive = $t('support.tabs.my-tickets')"
      >
        <AskInnoTab />
      </div>
      <div :slot="$t('support.tabs.my-tickets')">
        <MyTicketsTab />
      </div>
    </vuestic-tabs>
  </div>
</template>

<script>
import AskInnoTab from './tabs/AskInno'
import MyTicketsTab from './tabs/MyTickets'
export default {
  components: {
    AskInnoTab,
    MyTicketsTab
  },
  data() {
    return {
      tab: this.$t('support.tabs.ask-inno')
    }
  }
}
</script>

<style lang="scss">
.vuestic-tabs.tabs {
  .nav {
    max-width: 50% !important;
  }
}
</style>
